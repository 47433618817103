import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

// Component to handle the login process
const Signup = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({authorizationParams: {
      screen_hint: "signup",
      prompt: "login"
    }});
  }, [loginWithRedirect]);

  // return <div>Redirecting to signup...</div>;
};

// Main App component
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Signup />} />
        <Route path="/signup" element={<Signup />} />
      </Routes>
    </Router>
  );
}

// Home component for the root path
// const Home = () => {
//   return (
//     <div>
//       <h1>Welcome to the Phosphor Studio SignUp</h1>
//       <p>Use the /signup endpoint to log in.</p>
//     </div>
//   );
// };

export default App;