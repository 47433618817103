import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { auth0Domain, auth0ClientID, auth0RedirectURI, auth0Audience} from './config';

ReactDOM.render(
  <Auth0Provider
    domain={auth0Domain}
    clientId={auth0ClientID}
    redirectUri={auth0RedirectURI}
    audience={auth0Audience}
    scope="openid profile email"
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);